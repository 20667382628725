.search-results {
  max-height: 400px;
  overflow-y: auto;
  width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.search-results::-webkit-scrollbar {
  width: 8px;
}

.search-results {
  -ms-overflow-style: none;  
  scrollbar-width: none; 
}

.search-results::-webkit-scrollbar-track {
  background: transparent;
}

.search-results::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 4px;
}

.search-results::-webkit-scrollbar-thumb:hover {
  background: darkgrey;
}

.search-results a {
  text-decoration: none;
  color: inherit;
  font-weight: 600;
}

.search-results a:visited {
  color: inherit;
}

.result {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
  transition: background-color 0.3s ease;
}

.result:hover {
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.result img {
  height: 100px;
  margin-right: 10px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.result p {
  margin: 0;
  text-align: center;
  flex-grow: 1; 
}